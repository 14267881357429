import React from "react";


export const Navigation = () => {
    const [active, setActive] = React.useState(new Array<boolean>())
    return (
        <div
            className={
                "md:fixed md:h-20 flex md:grid p-0 grid-cols-12 " +
                "w-full h-20 bg-yellow-100"}>
            <nav className={"flex col-span-7 col-start-4"}>
                <img className={"hidden md:block flex-grow-0 my-auto mr-3 h-6"}
                     src={"./logo192.png"}
                     alt={"Jürgen Alesi Malergeschäft"}/>
                <Nav/>
            </nav>
        </div>
    )
}
function A(props: {text: string, href?:string, active?: string}) {
    return (
        <a href={props.href}
            className={
                "hover:text-green-700 cursor-pointer " +
                "text-xs md:text-base align-middle my-auto tracking-widest " +
                `${props.active}`}>
            { props.text }
        </a>
    )
}
function Nav() {
    return (
        <div className={"flex flex-wrap gap-4 m-auto"}>
            <A href={"#HERO"} text={"HOME"} active={"text-green-700"}/>
            <A href={"#ARBEITSFELDER"} text={"ARBEITSFELDER"}/>
            <A href={"#ARBEITEN"} text={"ARBEITEN"}/>
            <A href={"#OVERME"} text={"ÜBERMICH"}/>
            <A href={"#CONTACT"} text={"KONTAKT"}/>
        </div>
    )
}
