import React from "react"
import { Carousel, WidgetInterface } from "../components/portfolio"

export const Arbeiten = () => {
    const widgets: WidgetInterface[] = [
        {src: "img/fassade.jpeg", alt: "Fassaden"},
        {src: "img/malen1.jpeg", alt: "Aussenfassade"},
        {src: "img/malen2.jpeg", alt: "Zimmer"},
        {src: "img/malen3.jpeg", alt: "Schlafzimmer"},
        {src: "img/malen4.jpeg", alt: "Wohnzimmer"},
        {src: "img/malen5.jpeg", alt: "Küche Malen"},

    ]
    return (
        <div
            id={"ARBEITEN"}
            className={
                "flex flex-col md:grid grid-cols-12  h-full"
            }
        >
            <div className={
                "flex flex-col row-start-1 col-start-4 col-end-10 gap-10"
            }>
                <h1 className={"text-4xl font-black "}>
                    Arbeiten
                </h1>
                <Carousel widgets={widgets}/>
            </div>
        </div>
    )
}
