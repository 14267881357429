import React from "react"
import { Carousel, WidgetInterface } from "../components/portfolio";

export const Arbeitsfelder = () => {
    const widgets: WidgetInterface[] = [
        {src: "img/roller.svg", alt: "paint Roller",
            title: "Innen",
            text:
                "Ihr Wohnraum kann individuell mit Ihrer Wunschfarbe " +
                "gestaltet werden. Ich helfe Ihnen gerne beim Aussuchen."
        },
        {src: "img/fassaden.svg", alt: "",
            title:  "Fassaden",
            text:
                "Die Fassade ist eine Visitenkarte jeder " +
                "Immobilie. Fassadenanstriche sind nicht nur zur optischen " +
                "Verschönerung Ihrer Immobilie und steigert den Wert Ihrer " +
                "Immobilie."
        },
        {src: "img/repair.svg", alt: "",
            title: "Reperaturen",
            text:
                "Ich erledige allerlei Reparaturen im und ums Gebäude. " +
                "Bohrlöcher, Einschläge an Wänden oder Böden jeder Art"
        },
    ]
    return (
        <div
            id={"ARBEITSFELDER"}
            className={
                "flex flex-col md:grid grid-cols-12 grid-rows-6 h-full"}>
            <h1 className={"grid text-4xl font-black col-start-4"}>
                Arbeitsfelder
            </h1>
            <div className={"col-start-4 row-start-2 col-span-6 row-span-5"}>
                <Carousel widgets={widgets}/>
            </div>
        </div>
    )
}