import React from 'react'
import './App.css'
import { Navigation } from "./navigation"
import { HeroSection } from "./main/hero"
import {Arbeitsfelder} from "./main/arbeitsfelder";
import {Arbeiten} from "./main/arbeiten";
import {OverMe} from "./main/overme";
import {Contact} from "./main/contact";

function App() {
  return (
    <div className={"monte bg-yellow-100 px-8 flex flex-col gap-10"}>
        <Navigation/>
        <HeroSection/>
        <Arbeitsfelder/>
        <Arbeiten/>
        <OverMe/>
        <Contact/>
        <div className={"h-20"}/>
    </div>
  )
}

export default App;
