import React from "react"
import { Carousel, WidgetInterface } from "../components/portfolio"

export const OverMe = () => {
    const widgets: WidgetInterface[] = [
        {
            src: "img/person.svg", alt: "user",
            title: "Jürgen Alesi",
            text:
                "Ich bin Jürgen Alesi, wohnhaft in Thun und Freiberufler. " +
                "Ich bin ihr Maler in der Region Thun, der Ihnen preisgünstig " +
                "und effektiv ihre Malerbedürfnisse erfüllen kann. " +
                "Ob Fassaden, Innenräume oder kleine Reperaturen, ich bin ihr " +
                "Handwerker! Mit über 20 Jahren Erfahrung biete ich Ihnen einen " +
                "hochwertigen Service."
        },

    ]
    return (
        <div
            id={"OVERME"}
            className={
                "flex flex-col md:grid grid-cols-12  h-full"
            }
        >
            <div className={
                "flex flex-col row-start-1 col-start-4 col-end-10 gap-10"
            }>
                <h1 className={"text-4xl font-black "}>
                    Über Mich
                </h1>
                <div className={"bg-yellow-200 text-black p-8 shadow-md"}>
                    Ich bin Jürgen Alesi, wohnhaft in Thun und Freiberufler.
                    Ich bin ihr Maler in der Region Thun, der Ihnen preisgünstig
                    und effektiv ihre Malerbedürfnisse erfüllen kann.
                    Ob Fassaden, Innenräume oder kleine Reperaturen, ich bin ihr
                    Handwerker! Mit über 20 Jahren Erfahrung biete ich Ihnen einen
                    hochwertigen Service.
                </div>
                <img src={"img/juergen_malt.jpg"}/>
            </div>
        </div>
    )
}
