import React from "react"
import { PrimaryButton } from "../components/buttons";

export const HeroSection = () => {
    return (
        <div
            className={
                "flex flex-col h-full md:h-screen md:grid grid-cols-12 " +
                "grid-rows-3"}
            id={"HERO"}>
            <img
                className={"hidden md:block row-start-1 col-start-1 col-span-3 h-full mt-20"}
                src={"img/paint.svg"}
                alt={"painting splash"}/>
            <img className={"row-start-1 col-start-8 md:mt-auto col-span-2 w-20"}
                src={"logo192.png"}
                alt={"Jürgen Alesi Icon"}/>
            <h1 className={"flex flex-col row-start-2 col-start-8 col-span-2 " +
                           "text-7xl md:text-8xl"}>
                <span>Jürgen</span>
                <span>Alesi</span>
            </h1>
            <div className={"grid gap-8 row-start-2 col-start-4 col-span-2 pt-6"}>
                <h1 className={"grid text-4xl font-black"}>
                    <span>Ihr</span>
                    <span>Maler</span>
                    <span>in Thun</span>
                </h1>
                <p>
                    Ob Fassaden, Innenräume oder kleine Reperaturen,
                    ich bin ihr Handwerker! Mit über 20 Jahren Erfahrung
                    biete ich Ihnen einen hochwertigen Service.
                </p>
                <div className={"flex flex-col md:flex-row gap-8"}>
                    <PrimaryButton href={"#CONTACT"} text={"Kontakt"}/>
                    <a
                        href={"#ARBEITSFELDER"}
                        className={"hidden md:block underline my-auto"}>
                        Arbeitsfelder
                    </a>
                </div>
            </div>
        </div>
    )
}
