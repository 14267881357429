import React from "react"

export interface WidgetInterface {
    src: string
    alt: string
    title?: string
    text?: string
}

const Widget = (props: WidgetInterface) => {
    const advanced = (
        <div
            className={
                "flex flex-col bg-yellow-200 text-black p-8 " +
                "shadow-md "
            }>
            <div className={"flex gap-2"}>
                <h3 className={"text-2xl"}>{props.title}</h3>
                <img className={"h-5 my-auto"} src={props.src} alt={props.alt}/>
            </div>
            <p>{props.text}</p>
        </div>
    )
    const simple = (
        <div>
            <img
                className={
                    "h-full my-auto rounded-md shadow-md "
                }
                src={props.src}
                alt={props.alt}/>
        </div>
    )
    if (props.title == undefined) {
        return simple
    }
    else {
        return advanced
    }
}
export const Carousel = (props: {widgets: Array<any>}) => {
    return (
        <div className={"flex flex-col lg:grid grid-cols-2 gap-8"}>
            {props.widgets.map(widget =>
                <Widget
                    src={widget.src}
                    alt={widget.alt}
                    title={widget.title}
                    text={widget.text}
                />
            )}
        </div>
    )
}