import React from "react"


export const PrimaryButton = (props: {href: string, text: string}) => {
    return (
        <a
            href={props.href}
            className={
                "flex border-4 border-green-600 bg-green-600 " +
                "text-2xl text-white hover:text-4xl " +
                "shadow-md ring-4 ring-green-600 ring-offset-2 " +
                "rounded-md pl-5 pr-3 px-4"
            }>
            <span className={"m-auto"}>{props.text}</span>
            <span className={"rounded-full bg-red-100 h-2 w-2 animate-ping"}/>
        </a>
    )
}

