import React from "react"
import { Carousel, WidgetInterface } from "../components/portfolio"

export const Contact = () => {
    const widgets: WidgetInterface[] = [
        {
            src: "img/call.svg", alt: "call me",
            title: "Telefon",
            text: "076 308 38 20"
        },
        {
            src: "img/email.svg", alt: "email me",
            title: "Email",
            text: 'jürgen.alesi@gmail.com'
        },
    ]
    return (
        <div
            id={"CONTACT"}
            className={"flex flex-col md:grid grid-cols-12 h-full"}>
            <div className={
                "flex flex-col row-start-1 col-start-4 col-end-10 gap-10"}>
                <h1 className={"text-4xl font-black "}>
                    Kontaktieren Sie Mich
                </h1>
                <Carousel widgets={widgets}/>
            </div>
        </div>
    )
}